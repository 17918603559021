import "./wowza.scss";
import { ReactNode, useEffect, useRef } from "react";
import { loadScript } from "design-system/hooks/use-script";
import { MediaAssetImageProps } from "../media-asset/media-asset-image";
import { useDataLayer } from "frontend/hooks/use-data-layer";

export interface WowzaEmbedProps {
  videoPath: string;
  media?: MediaAssetImageProps;
  caption?: ReactNode;
}

export function WowzaEmbed({ videoPath, media, caption }: WowzaEmbedProps) {
  const videoRef = useRef<HTMLVideoElement>(null);
  const { pushDataLayer } = useDataLayer();

  useEffect(() => {
    const videoElement = videoRef.current;

    if (!videoElement) return;

    // push data layer event when video is played
    const handleOnClick = () => {
      pushDataLayer({
        event: "video",
        /* eslint-disable camelcase */
        event_data: {
          video_title: "livestream",
          video_type: "livestream",
        },
      });
    };

    videoElement.addEventListener("play", handleOnClick);

    return () => {
      videoElement.removeEventListener("play", handleOnClick);
    };
  }, [pushDataLayer]);

  // load video.js script
  useEffect(() => {
    if (videoPath) {
      loadScript("https://vjs.zencdn.net/7.11.4/video.min.js", {
        defer: true,
      });
    } else {
      console.warn("video.js script not loaded");
    }
  }, [videoPath]);

  // load video.js css
  useEffect(() => {
    const link = document.head.appendChild(
      Object.assign(document.createElement("link"), {
        rel: "stylesheet",
        href: "https://vjs.zencdn.net/7.11.4/video-js.css",
      }),
    );

    // add wowza class to stats topper
    const element = document.querySelector(".hbs-topper.hbs-statistics-topper");
    if (element) {
      element.classList.add("hbs-wowza-embed-topper");
    }

    return () => {
      // cleanup: remove dynamically added link
      document.head.removeChild(link);
    };
  }, []);

  return (
    <div className="hbs-media-asset">
      <video
        ref={videoRef}
        id="hbs-wowza-video"
        className="video-js vjs-big-play-centered"
        controls
        preload="none"
        data-setup='{"suppressNotSupportedError": true}'
        poster={media?.src || undefined}
      >
        <source src={videoPath} type="application/x-mpegURL" />
        <p className="vjs-no-js">
          To view this video please enable JavaScript, and consider upgrading to
          a web browser that supports HTML5 video.
        </p>
      </video>
      <figcaption>
        <span className="hbs-media-asset__caption-text">
          <div>{caption}</div>
        </span>
      </figcaption>
    </div>
  );
}
